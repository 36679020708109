import {
  Avatar,
  Button,
  Chip,
  Divider,
  Stack,
  Typography,
} from "@mui/material";
import StatsComponent from "../StatsComponent";
import ProfileCard from "../profilePcs.png";
import { AlternateEmail, Code, Engineering } from "@mui/icons-material";

export const Card = () => (
  <Stack
    className="card"
    sx={{
      borderRadius: "40px",
      overflow: "hidden",
      width: "350px",
      background: "#fff",
      boxShadow: "0px 1px 20px 6px rgba(0, 0, 0, 0.1)",
      padding: "25px",
      textAlign: "center",
      height: "100%",
      position: "relative",
      overflowY: "auto",
      "&::-webkit-scrollbar": {
        display: "none",
      },
      "-ms-overflow-style": "none",
      "scrollbar-width": "none",
    }}
  >
    <Stack spacing={1.5} alignItems="center" mt={5}>
      <Avatar
        alt="PopNaija"
        src={ProfileCard}
        sx={{ width: 160, height: 160 }} // Increased size of the avatar
      />
      <Chip
        avatar={<AlternateEmail sx={{ fontSize: 10 }} />}
        label={
          <Typography fontWeight="bold" variant="caption" color="text.primary">
            PopNaija
          </Typography>
        }
      />
      <Typography fontWeight="bold" variant="h6">
        Wednesday Akpan
      </Typography>
      <Typography variant="caption" color="text.primary" fontWeight="bold">
        Life is Programmed…
      </Typography>
      <Divider sx={{ marginY: 2 }} />
    </Stack>
    <Typography variant="caption" color="text.primary" fontWeight="bold">
      A Programmer, Full-time Coder, Web Manager, A.I Developer, SEO Expert,
      Media Buyer, Cybersecurity Expert and more…..
    </Typography>
    <Stack direction="row" spacing={4} justifyContent="center" my={2} mt={5}>
      <Chip avatar={<Code />} label="Developer" variant="outlined" />
      <Chip avatar={<Engineering />} label="Entrepreneur" variant="outlined" />
    </Stack>
  </Stack>
);
