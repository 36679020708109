import { useEffect, useRef, useState } from "react";
import { Avatar, Chip, Stack, Typography, IconButton } from "@mui/material";
import { QRCode } from "react-qrcode-logo";
import ProfileCard from "../profilePcs.png";
import {
  Facebook,
  Instagram,
  Mail,
  WhatsApp,
  ArrowDownward,
} from "@mui/icons-material";

export const Contact = () => {
  const [scrollIconVisible, setScrollIconVisible] = useState(false);
  const contentRef = useRef<any>(null);
  const lastScrollTop = useRef(0); // Track last scroll position to detect direction

  // Show scroll icon when user scrolls up
  const handleScroll = () => {
    const currentScrollTop = contentRef.current.scrollTop;
    const maxScrollTop =
      contentRef.current.scrollHeight - contentRef.current.clientHeight;

    // Check if scrolled to bottom, hide icon if true
    if (currentScrollTop >= maxScrollTop) {
      setScrollIconVisible(false);
    } else if (currentScrollTop < lastScrollTop.current) {
      // Show icon on scroll up
      setScrollIconVisible(true);
    }

    lastScrollTop.current = currentScrollTop;
  };

  // Scroll to bottom and hide icon when clicked
  const handleScrollToEnd = () => {
    contentRef.current.scrollTo({
      top: contentRef.current.scrollHeight,
      behavior: "smooth",
    });
    setScrollIconVisible(false);
  };

  useEffect(() => {
    // Initial icon display for 3 seconds
    setScrollIconVisible(true);
    const timer = setTimeout(() => setScrollIconVisible(false), 3000);
    return () => clearTimeout(timer);
  }, []);

  return (
    <Stack
      className="card body"
      ref={contentRef}
      onScroll={handleScroll}
      sx={{
        "&::-webkit-scrollbar": {
          display: "none",
        },
        "-ms-overflow-style": "none",
        "scrollbar-width": "none",
      }}
    >
      <Stack spacing={1.5} alignItems="center" mt={5}>
        <Typography fontWeight="bold" variant="h6" color="white">
          About the Guy?
        </Typography>
        <Typography variant="caption" color="white" fontWeight="bold">
          I’m a Full-Stack Developer with expertise in Mobile App, Web, AI, and
          Game Development.
        </Typography>
        <Typography variant="caption" color="white" fontWeight="bold">
          Proficient in React, React Native, Flutter, JavaScript, TypeScript,
          PHP, Python, TensorFlow, and Unreal Engine.
        </Typography>
        <Typography variant="caption" color="white" fontWeight="bold">
          I create user-focused solutions for iOS, Android, and the web. My
          skills span secure app authentication and sleek, modular UI/UX to
          robust backends powered by Firebase, Apache and REST APIs.
        </Typography>
        <Typography variant="caption" color="white" fontWeight="bold">
          Key projects include a finance management app with biometric login, an
          AI-driven security system using facial recognition (Deep Learning
          Algorithm), VR Games, and custom WordPress sites for enhanced
          functionality.
        </Typography>
        <Typography variant="caption" color="white" fontWeight="bold">
          I prioritize clean code, responsive design, and client satisfaction,
          aiming to bring value through innovative, efficient, and secure
          digital solutions.
        </Typography>
      </Stack>
      <Typography mt={5} fontWeight="bold" variant="h6" color="white">
        Contact the Guy:
      </Typography>
      <Stack direction="row" spacing={4} justifyContent="center" mb={2} mt={2}>
        <Chip
          avatar={<Instagram />}
          label="Instagram"
          variant="outlined"
          sx={{ color: "white", px: 1 }}
        />
        <Chip
          avatar={<WhatsApp />}
          label="WhatsApp"
          variant="outlined"
          sx={{ color: "white", px: 1 }}
        />
      </Stack>
      <Stack direction="row" spacing={4} justifyContent="center" mb={5}>
        <Chip
          avatar={<Facebook />}
          label="Facebook"
          variant="outlined"
          sx={{ color: "white", px: 1 }}
        />
        <Chip
          avatar={<Mail />}
          label="Send Mail"
          variant="outlined"
          sx={{ color: "white", px: 1 }}
        />
      </Stack>

      {/* Scroll Icon at Bottom */}
      {scrollIconVisible && (
        <IconButton
          onClick={handleScrollToEnd}
          sx={{
            position: "fixed",
            bottom: 16,
            left: "50%",
            transform: "translateX(-50%)",
            color: "white",
            backgroundColor: "rgba(0, 0, 0, 0.6)",
            borderRadius: "50%",
            zIndex: 1000, // Ensure it stays above content
          }}
        >
          <ArrowDownward />
        </IconButton>
      )}
    </Stack>
  );
};
