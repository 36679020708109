import { useState, useEffect } from "react";
import { Button, Container, Stack, Typography } from "@mui/material";
import { Card } from "./Component/Card";
import { ContactMail } from "@mui/icons-material";
import { Swiper, SwiperSlide } from "swiper/react";
import "./App.css";

import "swiper/css";
import "swiper/css/effect-flip";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { EffectFlip, Navigation } from "swiper/modules";
import { Contact } from "./Component/Contact";

function App() {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [swiperInstance, setSwiperInstance] = useState<any>(null);

  const handleSlideChange = (swiper: any) => {
    setCurrentIndex(swiper.activeIndex);
  };

  const toggleContactDetails = () => {
    // Toggle to the contact details slide or back
    setCurrentIndex((prevIndex) => (prevIndex === 0 ? 1 : 0));
    if (swiperInstance) {
      swiperInstance.slideTo(currentIndex === 0 ? 1 : 0);
    }
  };

  useEffect(() => {
    // Auto-flip to show contact slide after 2 seconds on initial load
    const autoFlipTimer = setTimeout(() => {
      if (swiperInstance) {
        swiperInstance.slideTo(1); // Go to Contact slide
      }
    }, 1000);

    // Flip back to the initial slide after another 2 seconds
    const resetFlipTimer = setTimeout(() => {
      if (swiperInstance) {
        swiperInstance.slideTo(0); // Go back to Card slide
      }
    }, 2000);

    return () => {
      clearTimeout(autoFlipTimer);
      clearTimeout(resetFlipTimer);
    };
  }, [swiperInstance]);

  return (
    <Container className="app-containers">
      <div className="background" />
      <Swiper
        effect={"flip"}
        loop={true}
        grabCursor={true}
        modules={[EffectFlip, Navigation]}
        className="swiper"
        onSlideChange={handleSlideChange}
        onSwiper={setSwiperInstance}
      >
        <SwiperSlide>
          <Card />
        </SwiperSlide>
        <SwiperSlide>
          <Contact />
        </SwiperSlide>
      </Swiper>

      <Stack mt={5}>
        <Button
          variant="contained"
          sx={{
            borderRadius: 40,
            textTransform: "none",
            display: "flex",
            alignItems: "center",
          }}
          startIcon={<ContactMail />}
          onClick={toggleContactDetails}
        >
          <Typography variant="body1">Contact Details</Typography>
        </Button>
      </Stack>
    </Container>
  );
}

export default App;
